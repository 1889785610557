<template>
  <footer
    class="bg-white"
    aria-labelledby="footer-heading"
  >
    <h2
      id="footer-heading"
      class="sr-only"
    >
      Footer
    </h2>
    <UContainer class="pt-16 sm:pt-24">
      <div class="grid grid-cols-12 gap-y-8">
        <div class="col-span-12 gap-y-2 pr-0 sm:col-span-5 sm:pr-8">
          <NuxtImg
            class="h-20"
            src="/upfan.svg"
            alt="Upfan"
          />
          <p class="my-4 text-gray-600">
            {{ $t('footer.description') }}
          </p>
          <div class="mt-4 flex gap-x-3">
            <ULink
              v-for="(social, index) in socialLinks"
              :key="index"
              :to="social.link"
              class="text-gray-400 hover:text-gray-500"
            >
              <span class="sr-only">{{ social.name }}</span>
              <UIcon
                :name="social.icon"
                class="size-6"
              />
            </ULink>
          </div>
          <LanguageSelector class="mt-4" />
        </div>
        <div class="col-span-12 grid grid-cols-2 sm:col-span-5 md:gap-8">
          <div>
            <h3 class="text-sm font-semibold leading-6 text-gray-900">
              {{ $t('footer.links.label') }}
            </h3>
            <ul
              role="list"
              class="mt-3 space-y-3"
            >
              <li
                v-for="item in navigation.links"
                :key="item.name"
              >
                <NuxtLink
                  :to="localePath(item.href)"
                  class="text-sm leading-6 text-gray-600 hover:text-gray-900"
                >{{ item.name }}</NuxtLink>
              </li>
            </ul>
          </div>
          <div>
            <h3 class="text-sm font-semibold leading-6 text-gray-900">
              {{ $t('footer.legal.label') }}
            </h3>
            <ul
              role="list"
              class="mt-3 space-y-3"
            >
              <li
                v-for="item in navigation.legal"
                :key="item.name"
              >
                <NuxtLink
                  :to="localePath(item.href)"
                  :target="item.blank ? '_blank' : '_self'"
                  class="text-sm leading-6 text-gray-600 hover:text-gray-900"
                >{{ item.name }}</NuxtLink>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-2">
          <h3 class="text-sm font-semibold leading-6 text-gray-900">
            {{ $t('footer.partnership.label') }}
          </h3>
          <UButton
            block
            to="mailto:info@upfan.io"
            class="mt-3"
            size="lg"
          >
            {{ $t('footer.partnership.contact-us.text') }}
          </UButton>
        </div>
      </div>
      <UDivider class="mt-16" />
      <h3 class="my-4 text-center text-sm text-gray-600">
        &copy; {{ DateTime.now().year }} {{ $t('footer.rights-reserved') }}
      </h3>
    </UContainer>
  </footer>
</template>

<script setup>
import { DateTime } from 'luxon'

const localePath = useLocalePath()
const { t } = useI18n()

const socialLinks = ref([
  // {
  //   icon: 'i-simple-icons-facebook',
  //   name: t('footer.socials.facebook.sr-only'),
  //   link: t('footer.socials.facebook.link'),
  // },
  // {
  //   icon: 'i-simple-icons-instagram',
  //   name: t('footer.socials.instagram.sr-only'),
  //   link: t('footer.socials.instagram.link'),
  // },
  // {
  //   icon: 'i-simple-icons-x',
  //   name: t('footer.socials.twitter.sr-only'),
  //   link: t('footer.socials.twitter.link'),
  // },
  {
    icon: 'i-simple-icons-linkedin',
    // name: t('footer.socials.linkedin.sr-only'),
    // link: t('footer.socials.linkedin.link'),
    name: 'LinkedIn',
    link: 'https://www.linkedin.com/company/upfan/',
  },
])

const navigation = computed(() => ({
  links: [
    { name: t('footer.links.home'), href: 'index' },
    { name: t('footer.links.events'), href: 'events' },
    { name: t('footer.links.tickets'), href: 'tickets' },
    { name: t('footer.links.about-us'), href: 'about' },
    { name: t('footer.links.profile'), href: 'auth-profile' },
  ],
  legal: [
    { name: t('footer.legal.terms'), blank: false, href: 'terms' },
    { name: t('footer.legal.gdpr'), blank: true, href: '/Upfan_GDPR_Infopoučenie_05072024.pdf' },
  ],
}))
</script>
