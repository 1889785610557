import { default as indexUr3G0EeeiXMeta } from "/tmp/build/app/pages/about/index.vue?macro=true";
import { default as profilepIyVTXeUoZMeta } from "/tmp/build/app/pages/auth/profile.vue?macro=true";
import { default as reset1jnvFPJ3PhMeta } from "/tmp/build/app/pages/auth/reset.vue?macro=true";
import { default as signinZkFOrf6t2SMeta } from "/tmp/build/app/pages/auth/signin.vue?macro=true";
import { default as signupvueEkPVWMmMeta } from "/tmp/build/app/pages/auth/signup.vue?macro=true";
import { default as indexsqa4td7bYeMeta } from "/tmp/build/app/pages/events/[id]/cart/index.vue?macro=true";
import { default as index9T2IPF5jw0Meta } from "/tmp/build/app/pages/events/[id]/index.vue?macro=true";
import { default as indexFnTfhJrKxMMeta } from "/tmp/build/app/pages/events/index.vue?macro=true";
import { default as index6VjhY0yt7sMeta } from "/tmp/build/app/pages/index.vue?macro=true";
import { default as indexT53PSHzLFYMeta } from "/tmp/build/app/pages/terms/index.vue?macro=true";
import { default as index5aUBaT4WYzMeta } from "/tmp/build/app/pages/tickets/index.vue?macro=true";
import { default as component_45stubbuBxueFkCRMeta } from "/tmp/build/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubbuBxueFkCR } from "/tmp/build/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about___sk",
    path: "/o-nas",
    component: () => import("/tmp/build/app/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___cs",
    path: "/cs/o-nas",
    component: () => import("/tmp/build/app/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/tmp/build/app/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-profile___sk",
    path: "/profil",
    meta: profilepIyVTXeUoZMeta || {},
    component: () => import("/tmp/build/app/pages/auth/profile.vue").then(m => m.default || m)
  },
  {
    name: "auth-profile___cs",
    path: "/cs/profil",
    meta: profilepIyVTXeUoZMeta || {},
    component: () => import("/tmp/build/app/pages/auth/profile.vue").then(m => m.default || m)
  },
  {
    name: "auth-profile___en",
    path: "/en/profile",
    meta: profilepIyVTXeUoZMeta || {},
    component: () => import("/tmp/build/app/pages/auth/profile.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset___sk",
    path: "/obnovit-heslo",
    meta: reset1jnvFPJ3PhMeta || {},
    component: () => import("/tmp/build/app/pages/auth/reset.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset___cs",
    path: "/cs/obnovit-heslo",
    meta: reset1jnvFPJ3PhMeta || {},
    component: () => import("/tmp/build/app/pages/auth/reset.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset___en",
    path: "/en/reset-password",
    meta: reset1jnvFPJ3PhMeta || {},
    component: () => import("/tmp/build/app/pages/auth/reset.vue").then(m => m.default || m)
  },
  {
    name: "auth-signin___sk",
    path: "/prihlasenie",
    meta: signinZkFOrf6t2SMeta || {},
    component: () => import("/tmp/build/app/pages/auth/signin.vue").then(m => m.default || m)
  },
  {
    name: "auth-signin___cs",
    path: "/cs/prihlaseni",
    meta: signinZkFOrf6t2SMeta || {},
    component: () => import("/tmp/build/app/pages/auth/signin.vue").then(m => m.default || m)
  },
  {
    name: "auth-signin___en",
    path: "/en/signin",
    meta: signinZkFOrf6t2SMeta || {},
    component: () => import("/tmp/build/app/pages/auth/signin.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup___sk",
    path: "/registracia",
    meta: signupvueEkPVWMmMeta || {},
    component: () => import("/tmp/build/app/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup___cs",
    path: "/cs/registrace",
    meta: signupvueEkPVWMmMeta || {},
    component: () => import("/tmp/build/app/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup___en",
    path: "/en/signup",
    meta: signupvueEkPVWMmMeta || {},
    component: () => import("/tmp/build/app/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "events-id-cart___sk",
    path: "/podujatia/:id()/kosik",
    component: () => import("/tmp/build/app/pages/events/[id]/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "events-id-cart___cs",
    path: "/cs/udalosti/:id()/kosik",
    component: () => import("/tmp/build/app/pages/events/[id]/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "events-id-cart___en",
    path: "/en/events/:id()/cart",
    component: () => import("/tmp/build/app/pages/events/[id]/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "events-id___sk",
    path: "/podujatia/:id()",
    component: () => import("/tmp/build/app/pages/events/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "events-id___cs",
    path: "/cs/udalosti/:id()",
    component: () => import("/tmp/build/app/pages/events/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "events-id___en",
    path: "/en/events/:id()",
    component: () => import("/tmp/build/app/pages/events/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "events___sk",
    path: "/podujatia",
    component: () => import("/tmp/build/app/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "events___cs",
    path: "/cs/udalosti",
    component: () => import("/tmp/build/app/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "events___en",
    path: "/en/events",
    component: () => import("/tmp/build/app/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "index___sk",
    path: "/",
    meta: index6VjhY0yt7sMeta || {},
    component: () => import("/tmp/build/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___cs",
    path: "/cs",
    meta: index6VjhY0yt7sMeta || {},
    component: () => import("/tmp/build/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: index6VjhY0yt7sMeta || {},
    component: () => import("/tmp/build/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "terms___sk",
    path: "/obchodne-podmienky",
    component: () => import("/tmp/build/app/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: "terms___cs",
    path: "/cs/obchodni-podminky",
    component: () => import("/tmp/build/app/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: "terms___en",
    path: "/en/terms",
    component: () => import("/tmp/build/app/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: "tickets___sk",
    path: "/vstupenky",
    meta: index5aUBaT4WYzMeta || {},
    component: () => import("/tmp/build/app/pages/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: "tickets___cs",
    path: "/cs/vstupenky",
    meta: index5aUBaT4WYzMeta || {},
    component: () => import("/tmp/build/app/pages/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: "tickets___en",
    path: "/en/tickets",
    meta: index5aUBaT4WYzMeta || {},
    component: () => import("/tmp/build/app/pages/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubbuBxueFkCRMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubbuBxueFkCR
  },
  {
    name: component_45stubbuBxueFkCRMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubbuBxueFkCR
  },
  {
    name: component_45stubbuBxueFkCRMeta?.name,
    path: "/sk-sitemap.xml",
    component: component_45stubbuBxueFkCR
  },
  {
    name: component_45stubbuBxueFkCRMeta?.name,
    path: "/cs-sitemap.xml",
    component: component_45stubbuBxueFkCR
  },
  {
    name: component_45stubbuBxueFkCRMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubbuBxueFkCR
  }
]